import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { loader } from '@monaco-editor/react';
import * as Sentry from '@sentry/react';
import { LicenseInfo } from '@mui/x-data-grid-premium';
import App from './App';
import configureStore, { history } from './redux/configureStore';
import { ToastUtils } from './utils/ToastUtils';
import { SentryUtils } from './utils/SentryUtils';

LicenseInfo.setLicenseKey(
  '5a49e63fc939eb99c4aa3489738362d3Tz0xMDcxMTQsRT0xNzcxMjg2Mzk5MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y',
);

if (SENTRY_DSN) {
  console.warn('Sentry Error Reporting is enabled');
  Sentry.init({
    dsn: SENTRY_DSN,
    release: `frontend@${VERSION}`,
    environment: window.location.hostname,
    attachStacktrace: true,
    autoSessionTracking: true,
    integrations: [Sentry.replayIntegration({ maskAllText: false })],
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
    beforeSend(event: Sentry.ErrorEvent) {
      if (!SentryUtils.shouldIgnoreEvent(event)) {
        ToastUtils.showToastWithErrorReportingId(SentryUtils.getMessageFromEvent(event), event.event_id);
      }
      return event;
    },
  });
}

loader.config({ paths: { vs: '/monaco-editor/vs' } });
export const store = configureStore();

const root = createRoot(document.getElementById('root')!);
root.render(<App store={store} history={history} />);
