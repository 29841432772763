import { StandardDatabaseSlugs } from './StandardDatabaseSlugs.enum';
import { StandardDatabaseDefinition } from './StandardDatabaseDefinition.interface';
import { DatabaseColumnStatuses } from '../DatabaseColumnStatuses.enum';
import { DatabaseColumnTypes } from '../DatabaseColumnTypes.enum';

export enum StandardDatabaseNames {
  Assets = 'Assets',
  Machines = 'Machines',
  Workstations = 'Workstations',
  WorkOrders = 'Work Orders',
  ProductionLines = 'Production Lines',
  AssetTypes = 'Asset Types',
  MachineTypes = 'Machine Types',
  MaterialTypes = 'Material Types',
  ProductTypes = 'Product Types',
}

const ProductionLineStatuses = [
  DatabaseColumnStatuses.Changeover,
  DatabaseColumnStatuses.Idle,
  DatabaseColumnStatuses.MaintenancePlanned,
  DatabaseColumnStatuses.MaintenanceUnplanned,
  DatabaseColumnStatuses.Running,
];

const MachineStatuses = [
  DatabaseColumnStatuses.Changeover,
  DatabaseColumnStatuses.Error,
  DatabaseColumnStatuses.Idle,
  DatabaseColumnStatuses.MaintenancePlanned,
  DatabaseColumnStatuses.MaintenanceUnplanned,
  DatabaseColumnStatuses.Running,
  DatabaseColumnStatuses.Shutdown,
  DatabaseColumnStatuses.Standby,
  DatabaseColumnStatuses.StartUp,
  DatabaseColumnStatuses.Testing,
];

const WorkstationStatuses = [
  DatabaseColumnStatuses.Active,
  DatabaseColumnStatuses.Changeover,
  DatabaseColumnStatuses.Error,
  DatabaseColumnStatuses.Idle,
  DatabaseColumnStatuses.MaintenancePlanned,
  DatabaseColumnStatuses.MaintenanceUnplanned,
  DatabaseColumnStatuses.Shutdown,
  DatabaseColumnStatuses.Testing,
];

export const STANDARD_DATABASES: StandardDatabaseDefinition[] = [
  {
    name: StandardDatabaseNames.AssetTypes,
    slug: StandardDatabaseSlugs.AssetType,
    columns: [
      {
        name: 'Name',
        slug: 'Name',
        type: DatabaseColumnTypes.STRING,
        required: true,
      },
      {
        name: 'Picture',
        slug: 'Picture',
        type: DatabaseColumnTypes.MEDIA,
      },
    ],
  },
  {
    name: StandardDatabaseNames.MachineTypes,
    slug: StandardDatabaseSlugs.MachineType,
    columns: [
      {
        name: 'Name',
        slug: 'Name',
        type: DatabaseColumnTypes.STRING,
        required: true,
      },
      {
        name: 'Picture',
        slug: 'Picture',
        type: DatabaseColumnTypes.MEDIA,
      },
    ],
  },
  {
    name: StandardDatabaseNames.MaterialTypes,
    slug: StandardDatabaseSlugs.MaterialType,
    columns: [
      {
        name: 'Name',
        slug: 'Name',
        type: DatabaseColumnTypes.STRING,
        required: true,
      },
      {
        name: 'Picture',
        slug: 'Picture',
        type: DatabaseColumnTypes.MEDIA,
      },
    ],
  },
  {
    name: StandardDatabaseNames.ProductTypes,
    slug: StandardDatabaseSlugs.ProductType,
    columns: [
      {
        name: 'Name',
        slug: 'Name',
        type: DatabaseColumnTypes.STRING,
        required: true,
      },
      {
        name: 'Picture',
        slug: 'Picture',
        type: DatabaseColumnTypes.MEDIA,
      },
    ],
  },
  {
    name: StandardDatabaseNames.Assets,
    slug: StandardDatabaseSlugs.Asset,
    columns: [
      {
        name: 'Type',
        slug: 'Type',
        type: DatabaseColumnTypes.REFERENCE,
        reference: StandardDatabaseSlugs.AssetType,
        required: true,
      },
      {
        name: 'Location',
        slug: 'Location',
        type: DatabaseColumnTypes.LOCATION,
      },
      {
        name: 'plantId',
        slug: 'plantId',
        type: DatabaseColumnTypes.STRING,
        system: true,
      },
    ],
  },
  {
    name: StandardDatabaseNames.ProductionLines,
    slug: StandardDatabaseSlugs.ProductionLine,
    columns: [
      {
        name: 'Name',
        slug: 'Name',
        type: DatabaseColumnTypes.STRING,
        required: true,
      },
      {
        name: 'Status',
        slug: 'Status',
        type: DatabaseColumnTypes.ENUM,
        enum: ProductionLineStatuses,
      },
    ],
  },
  {
    name: StandardDatabaseNames.Machines,
    slug: StandardDatabaseSlugs.Machine,
    columns: [
      {
        name: 'Type',
        slug: 'Type',
        type: DatabaseColumnTypes.REFERENCE,
        reference: StandardDatabaseSlugs.MachineType,
        required: true,
      },
      {
        name: 'Status',
        slug: 'Status',
        type: DatabaseColumnTypes.ENUM,
        enum: MachineStatuses,
      },
      {
        name: 'Production Line',
        slug: 'ProductionLine',
        type: DatabaseColumnTypes.REFERENCE,
        reference: StandardDatabaseSlugs.ProductionLine,
      },
      {
        name: 'Location',
        slug: 'Location',
        type: DatabaseColumnTypes.LOCATION,
      },
      {
        name: 'Current Product Type',
        slug: 'CurrentProductType',
        type: DatabaseColumnTypes.REFERENCE,
        reference: StandardDatabaseSlugs.ProductType,
      },
      {
        name: 'plantId',
        slug: 'plantId',
        type: DatabaseColumnTypes.STRING,
        system: true,
      },
    ],
  },
  {
    name: StandardDatabaseNames.Workstations,
    slug: StandardDatabaseSlugs.Workstation,
    columns: [
      {
        name: 'Name',
        slug: 'Name',
        type: DatabaseColumnTypes.STRING,
        required: true,
      },
      {
        name: 'Status',
        slug: 'Status',
        type: DatabaseColumnTypes.ENUM,
        enum: WorkstationStatuses,
      },
      {
        name: 'Production Line',
        slug: 'ProductionLine',
        type: DatabaseColumnTypes.REFERENCE,
        reference: StandardDatabaseSlugs.ProductionLine,
      },
      {
        name: 'Location',
        slug: 'Location',
        type: DatabaseColumnTypes.LOCATION,
      },
      {
        name: 'Current Product Type',
        slug: 'CurrentProductType',
        type: DatabaseColumnTypes.REFERENCE,
        reference: StandardDatabaseSlugs.ProductType,
      },
      {
        name: 'plantId',
        slug: 'plantId',
        type: DatabaseColumnTypes.STRING,
        system: true,
      },
    ],
  },
  {
    name: StandardDatabaseNames.WorkOrders,
    slug: StandardDatabaseSlugs.WorkOrder,
    columns: [
      {
        name: 'Product Type',
        slug: 'ProductType',
        type: DatabaseColumnTypes.REFERENCE,
        reference: StandardDatabaseSlugs.ProductType,
        required: true,
      },
      {
        name: 'Quantity',
        slug: 'Quantity',
        type: DatabaseColumnTypes.STRING,
        required: true,
      },
    ],
  },
];
